
import Vue from "vue";
import { getAuthorizationBearerStringFor } from "@/services/base.service";
import * as types from "@/store/mutation-types";
import { getPathAPI } from "@/helpers";
import { omitBy, isNull, isUndefined, capitalize } from "lodash";
import {
  atlasfileMapActions,
  atlasfileMapMutations
} from "@/store/modules/atlasfile";
import { userRoleArr } from "@/types";

interface NewUploadData {
  loading: boolean;
  fileList: any[];
  uploadData: {
    name: string;
    isPublic: boolean;
    category: string;
    documentAccessConfig: {
      allowedUsers: string[];
      allowedRoles: string[];
      allowedAgencies: string[];
    };
  };
  server_base: string;
  message: string;
  error: string;
  value: string;
  atlasFilesCategoryOptions: any[];
  userRoleOptions: any[];
  loadingText: string;
  deleteOldRecords: boolean;
}

export default Vue.extend({
  name: "UploadArea",
  components: {
    SelectSearchUsers: () =>
      import("@/components/FormFields/SelectSearchUsers.vue"),
    SelectSearchAgencies: () =>
      import("@/components/FormFields/SelectSearchAgencies.vue")
  },
  props: {
    showUploader: {
      type: Boolean,
      required: false,
      default: true
    },
    showHeading: {
      type: Boolean,
      required: false,
      default: true
    },
    showUploadConfigs: {
      type: Boolean,
      required: false,
      default: true
    },
    isReplaceAction: {
      type: Boolean,
      default: false
    },
    uploadIcon: {
      type: String,
      default: "cloud-upload"
    },
    uploadMessageHtml: {
      type: String,
      default: "Drop files here or <em>click to upload</em>"
    },
    acceptedFiles: {
      type: String,
      default: ".jpg, .jpeg, .png, .bmp, .pdf, .zip, .csv"
    },
    autoUpload: {
      type: Boolean,
      default: false
    },
    showFileList: {
      type: Boolean,
      default: true
    },
    acceptMultiple: {
      type: Boolean,
      default: true
    },
    showSettings: {
      type: Boolean,
      default: true
    },
    customUploadTypes: {
      type: Array
    },
    canChangeUploadType: {
      type: Boolean,
      default: true
    },
    uploadLimit: {
      type: Number,
      default: 5
    },
    hideUploadButton: {
      type: Boolean,
      required: false,
      default: false
    },
    hasSelectedFilesToUpload: {
      type: Boolean,
      default: false
    },
    uploadUrl: {
      type: String,
      required: false,
      default: undefined
    },
    dataForUpload: {
      type: Object,
      required: false,
      default: () => ({})
    },
    uploadType: {
      type: String,
      required: false,
      default: "none"
    },
    categoryOptions: {
      type: Array,
      default: () => []
    },
    showDeleteOldCheckbox: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    hasFilesToUpload: {
      handler(val: boolean) {
        this.$emit("update:hasSelectedFilesToUpload", val);
      }
    }
  },
  data(): NewUploadData {
    return {
      loading: false,
      uploadData: {
        name: "",
        isPublic: false,
        category: "None",
        documentAccessConfig: {
          allowedUsers: [],
          allowedRoles: [],
          allowedAgencies: []
        }
      },
      server_base: getPathAPI(),
      fileList: [],
      message: "",
      error: "",
      value: "",
      atlasFilesCategoryOptions: this.categoryOptions,
      userRoleOptions: userRoleArr.map((role: string) => ({
        label: capitalize(role),
        value: role
      })),
      loadingText: "",
      deleteOldRecords: true
    };
  },
  methods: {
    ...atlasfileMapMutations([types.SET_STATE]),
    ...atlasfileMapActions(["createAtlasfile"]),
    handleRemove(file: any): void {
      this.fileList.splice(
        this.fileList.map((file: any) => file.uid).indexOf(file.uid),
        1
      );
    },
    handleChange(fileChanged: any): void {
      const fileExists = this.fileList.find(
        file => file.uid === fileChanged.uid
      );
      if (!fileExists) {
        this.fileList.push(fileChanged);
      }
    },
    submitUpload(): void {
      const field = this.$refs.upload as Vue;
      if (field) {
        field.submit();
      }
    },
    onBeforeFileRemove(file: any, fileList: any[]): void {
      this.$emit("beforeFileRemove", file, fileList);
    },
    onBeforeFileUpload: function(file: any): void {
      this.loading = true;
      this.loadingText = "Uploading file. Please wait...";
      this.$emit("beforeFileUpload", file);
    },
    onFileUploadSuccess(response: any, file?: any): void {
      this.loading = false;
      this.loadingText = "";
      this.$emit("fileUploadSuccess", response, file);
    },
    onFileUploadError(err: any): void {
      const parseError = JSON.parse(err.message);
      this.$emit("fileUploadError", parseError.response);
      this.loading = false;
      this.loadingText = "";
      let error = "There was an error uploading.";
      try {
        error = parseError.response.message;
      } finally {
        this.error = error;
      }
    },
    handleLimitExceeded(): void {
      this.$appNotify({
        message: `You are allowed to import only ${this.uploadLimit} files at a time`,
        type: "info",
        title: "Info"
      });
    }
  },
  computed: {
    hasFilesToUpload(): boolean {
      return this.fileList.length > 0;
    },
    uploadDataForAutoUpload: function(): any {
      const res: { [key: string]: any } = {};
      const obje = {
        ...omitBy(
          this.dataForUpload,
          (f: any) => f === "" || isNull(f) || isUndefined(f)
        ),
        ...(this.showUploadConfigs ? this.uploadData : {}),
        ...{ type: this.uploadType },
        ...(this.showDeleteOldCheckbox && {
          deleteOldRecords: this.deleteOldRecords ? "yes" : "no"
        })
      };

      function recurse(obj: any, current?: any) {
        for (var key in obj) {
          var value = obj[key];
          var newKey = current ? current + "." + key : key;
          if (value && typeof value === "object") {
            // recurse(value, newKey); //TODO: maybe rethink and ...
            res[newKey] = JSON.stringify(value);
          } else {
            res[newKey] = value;
          }
        }
      }
      recurse(obje);
      return res;
    },
    headers: function(): any {
      return {
        Authorization: getAuthorizationBearerStringFor("access")
      };
    },
    actionUrl(): string {
      const relativeUploadUrl = this.uploadUrl ? this.uploadUrl : "atlas-files";
      return `${getPathAPI()}/${relativeUploadUrl}`;
    },
    showLoader(): boolean {
      return this.loading && this.uploadType !== "agentReport";
    }
  }
});
